import EventBus from '@/utils/EventBus';
import { mapActions, mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters({
            backModal: 'modals/getLastModal',
            getModalByRouteName: 'modals/getModalByRouteName',
            getLastFullPageRouteName: 'full_pages/getLastFullPageRouteName',
            getLastModal: 'modals/getLastModal',
            activeModalTitle: 'modals/getActiveModalTitle',
            activeBreadcrumbName: 'modals/getActiveBreadcrumbName',
        }),
    },
    methods: {
        ...mapActions({
            pushModal: 'modals/pushModal',
            popAllModals: 'modals/popAllModals',
            removeModalByRouteName: 'modals/removeModalByRouteName',
            setActiveModalTitle: 'modals/setActiveModalTitle',
            setActiveModalBreadcrumbName: 'modals/setActiveModalBreadcrumbName',
        }),
        /*
         *  helper method to set title and breadcrumb name for the active modal.
         */
        setActiveModalName(name) {
            if (!name) {
                return;
            }

            this.setActiveModalTitle(name.toLowerCase());
            this.setActiveModalBreadcrumbName(name.toLowerCase());
        },
        /*
         *  helper method to navigate to a given route name.
         */
        redirect: function (routeName, params) {
            if (!routeName) {
                return;
            }
            this.$router.push({ name: routeName, params }).then(() => {});
        },
        /*
         *  helper method to close the active modal.
         */
        close() {
            if (this.backModal) this.$router.push({ name: this.backModal.routeName, params: this.backModal.params }).then(() => {});
            else this.$router.push({ name: this.getLastFullPageRouteName }).then(() => {});
        },
        hideDownloadIndicator() {
            EventBus.emit('hide-download-indicator');
        },
        handleNavigateForward({ from }) {
            // Fix multiple usage of ModalNavigation mixin inside components on the same view
            if (this.getLastModal?.routeName === from.name) {
                return;
            }

            // cleanup images to avoid retrieving revoked blobs
            this.$el.querySelectorAll('img[src^="blob:"]').forEach(el => {
                el.src = '';
            });

            this.pushModal({
                template: this.$el.outerHTML,
                routeName: from.name,
                params: from.params,
                breadcrumbName: this.activeBreadcrumbName || from.meta.breadcrumbName,
                title: this.activeModalTitle,
            });
        },
        handleNavigateBack({ to }) {
            this.removeModalByRouteName(to.name);
        },
    },
    mounted() {
        EventBus.on('navigateForward', this.handleNavigateForward);
        EventBus.on('navigateBack', this.handleNavigateBack);
    },
    beforeUnmount() {
        EventBus.off('navigateForward', this.handleNavigateForward);
        EventBus.off('navigateBack', this.handleNavigateBack);

        this.setActiveModalBreadcrumbName(undefined);
    },
};
