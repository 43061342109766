<template>
  <div class="h-full flex min-w-full">
    <loader :loading="loading" :backdrop="true" />
    <div class="flex-auto" ref="embeddingContainer"></div>
  </div>
</template>

<script>
import AuthMixin from '@/components/auth/AuthMixin'
import ModalNavigation from '@/mixins/ModalNavigation'
import NotifyMixin from '@/mixins/NotifyMixin'
import Loader from '@/components/ui/Loader'
import { embedDashboard } from 'amazon-quicksight-embedding-sdk';

export default {
  components: { Loader },
  mixins: [ AuthMixin,
            NotifyMixin, 
            ModalNavigation            
          ],
   data: () => {
    return {
      loading: false,
      embedOptions: {
        url: "",  
        container: "",
        parameters:{
          communityUUID : ""
        },
        scrolling: "no",
        height: "100%",
        iframeResizeOnSheetChange: true,
        width: "100%",
        footerPaddingEnabled: false,
        sheetTabsDisabled: true,
        printEnabled: false,
        undoRedoDisabled: true,
        resetDisabled: true,
      },
    }
  },
  methods: {
    getEmbedUrl(){
      this.loading = true;
      this.$dhDataProvider
      .getList('dashboardEmbedUrl', { communityId: this.community?.id })
      .then((res) => {
        if(res.statusCode != 200){
          this.notifyError(res.error)
        }else{
          const data = res.data
          this.embedOptions.url = data.EmbedUrl
        }
      })
      .catch((error) => this.notifyError(error.message))
      .finally(() => {
        this.loading = false;
      })
    },
  },
  watch:{
    'embedOptions.url': function(){
        embedDashboard(this.embedOptions);
     }
  },
  created(){
    if (!this.community) {
      this.notifyError(
        'please select a community to continue, then refresh the browser'
      )
    } else {
      this.embedOptions.parameters.communityUUID = this.community?.id;
      this.getEmbedUrl()
    }
  },
  mounted() {
    this.embedOptions.container = this.$refs.embeddingContainer
  }
}
</script>

<style scoped>
.form {
  width: 55%;
}
.label-container {
  width: 53%;
}
.active {
  color: var(--highlightColor500);
  border-bottom: 2px solid;
  border-color: var(--highlightColor500);
  margin: 0 5px 0 5px;
}
</style>
